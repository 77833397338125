"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseExperimentsPayload = exports.articleQuestionIntent = exports.foodType = exports.articleSearchPageViewObjectCategory = exports.recipesListPageViewObjectCategory = exports.createFoodstuffPageViewOriginName = exports.foodstuffPageViewIntent = exports.createRecipePageViewOriginName = exports.createRecipePageViewIntent = exports.recipePageViewIntent = exports.startProgramViewOriginName = exports.logFoodPageViewOriginName = exports.loggedFoodOriginType = exports.loggedFoodOriginName = exports.loggedFoodIntent = exports.recipeCategory = exports.originName = exports.objectCategory = exports.objectElementType = exports.objectType = void 0;
const type = {
    view: 'View',
    clicked: 'Clicked',
    logged: 'Logged', // use completed combined with eventObjectType.action for newly created events
    started: 'Started', // use completed combined with eventObjectType.action for newly created events
    set: 'Set', // use completed combined with eventObjectType.action for newly created events
    added: 'Added', // use completed combined with eventObjectType.action for newly created events
    removed: 'Removed', // use completed combined with eventObjectType.action for newly created events
    completed: 'Completed',
    achieved: 'Achieved'
};
exports.objectType = {
    button: 'Button',
    view: 'Screen',
    food: 'Food',
    activity: 'Activity',
    weight: 'Weight',
    waist: 'Waist',
    mood: 'Mood',
    program: 'Program',
    filters: 'Filters',
    water: 'Water',
    action: 'Action',
    image: 'Image',
    goal: 'Goal',
    link: 'Link',
    page: 'Page',
    item: 'Item',
    uiElement: 'UIElement'
};
exports.objectElementType = {
    teaser: 'Teaser',
    blueLink: 'Blue link'
};
exports.objectCategory = {
    weeklyMenu: {
        weekly: 'weekly',
        daily: 'daily'
    },
    onboarding: 'onboarding',
    edit: 'edit',
    footerAd: 'Footer Ad',
    howItWorksMid: 'How it works - mid page ad',
    blueLink: 'Blue link',
    singleQuestion: {
        rightAnswer: 'right',
        wrongAnswer: 'wrong'
    }
};
const objectName = {
    weeklyMenu: 'Weekly menu',
    weeklyMenuIntroduction: 'Weekly menu - introduction',
    weeklyMenuSettings: 'Weekly menu settings',
    menuSettings: 'Menu settings',
    menuProteinsSet: 'Menu proteins set',
    menuCookingSkillsSet: 'Menu cooking skills set',
    menuChallengesSet: 'Menu challenges set',
    weeklyMenuFakeCalculations: 'Weekly menu - fake calculations',
    weeklyMenuOnboardingSummary: 'Weekly menu - onboarding wrap-up',
    changeMenuView: 'Change menu view',
    deleteLoggedItem: 'Delete logged item',
    cancelCustomizingMenu: 'Cancel customizing menu',
    customizeMenu: 'Customize menu',
    customizeMenuLater: 'Customize menu later',
    menuSwitchMeal: 'Menu switch meal',
    menuMealSwitched: 'Menu meal switched',
    similarRecipe: 'Similar recipe',
    popularTagsOnFeaturedRecipes: 'Popular tags on featured recipes',
    relatedArticles: 'Related articles',
    articleList: 'Article list',
    sectionList: 'Section list',
    successStories: 'Success stories',
    frontpageLatestArticles: 'Frontpage latest articles',
    singleQuestion: 'Question'
};
exports.originName = {
    logbookReminder: 'Logbook reminder',
    logbookStandard: 'Logbook standard',
    logbookOverride: 'Logbook override',
    navigationBar: 'Navigation bar',
    landingPageBanner: 'Landing page banner',
    weeklyMenu: 'Weekly menu',
    menuOnboarding: 'Menu onboarding',
    changeMenuView: 'Change menu view',
    recipe: 'Recipe'
};
const printRecipeOriginName = {
    logbook: 'Logbook',
    recipe: 'Recipe'
};
exports.recipeCategory = {
    userRecipe: 'User recipe',
    systemRecipe: 'System recipe'
};
const programType = {
    keepWeight: 'Keep weight',
    loseWeight: 'Lose weight'
};
const accessLevel = {
    paid: 'paid',
    free: 'free'
};
exports.loggedFoodIntent = {
    logRecipe: 'Log recipe',
    logFoodstuff: 'Log foodstuff',
    logCalories: 'Log calories'
};
exports.loggedFoodOriginName = {
    commonlyLogged: 'Logging HQ commonly logged item view',
    commonlyLoggedFastLog: 'Logging HQ commonly logged fast log',
    search: 'Search',
    favourites: 'Logging HQ favourites item view',
    favouritesFasttLog: 'Logging HQ favourites fast log',
    menu: 'Logging HQ menu item view',
    menuFastLog: 'Logging HQ menu fast log',
    userItems: 'Logging HQ user items item view',
    useItemsFastLog: 'Logging HQ user items fast log',
    featured: 'Featured',
    plannedMenu: 'Logging HQ menu item view',
    plannedMenuFastLog: ' Logging HQ menu fast log',
    copy: 'Copy',
    campaign: 'Campaign',
    createRecipe: 'Create recipe',
    saveSharedRecipe: 'Save shared recipe',
    weeklyMenu: 'Weekly menu',
    fastLogAtWeeklyMenu: 'Fast log @ Weekly menu',
    fastLogMealAtWeeklyMenu: 'Fast log meal @ Weekly menu',
    commonlyLoggedFastUnlogged: 'Commonly logged fast unlog'
};
exports.loggedFoodOriginType = {
    button: 'Button',
    search: 'Search'
};
exports.logFoodPageViewOriginName = {
    commonlyLogged: 'Logging HQ commonly logged item view',
    favourites: 'Logging HQ favourites item view',
    search: 'Search',
    menu: 'Logging HQ menu item view',
    userItems: 'Logging HQ user items item view',
    recipe: 'Recipe'
};
exports.startProgramViewOriginName = {
    startFirstProgram: 'Start first program',
    startNewProgram: 'Start new program',
    restartUnfinishedProgram: 'Restart unfinished program'
};
exports.recipePageViewIntent = {
    logRecipe: 'Log recipe',
    editOrDeleteLoggedRecipe: 'Edit or delete logged recipe',
    modifyCopiedMeal: 'Modify copied meal',
    viewRecipeDetails: 'View recipe details',
    similarRecipe: 'Clicked Item Similar recipe'
};
exports.createRecipePageViewIntent = {
    createRecipe: 'Create recipe',
    copyRecipe: 'Copy recipe',
    editRecipe: 'Edit recipe'
};
exports.createRecipePageViewOriginName = {
    userRecipes: 'User recipes',
    userItems: 'User items',
    logRecipe: 'Log recipe',
    recipeDetails: 'Recipe details',
    loggingHQ: 'Logging HQ',
    search: 'Search',
    campaign: 'Campaign'
};
exports.foodstuffPageViewIntent = {
    logFoodstuff: 'Log foodstuff',
    editOrDeleteLoggedFoodstuff: 'Edit or delete logged foodstuff',
    modifyCopiedMeal: 'Modify copied meal',
    createFoodstuff: 'Create foodstuff',
    editFoodstuff: 'Edit foodstuff',
    createFoodstuffForBarcodeConnection: 'Create foodstuff for barcode connection'
};
exports.createFoodstuffPageViewOriginName = {
    loggingHQ: 'Logging HQ',
    search: 'Search'
};
exports.recipesListPageViewObjectCategory = {
    userRecipes: 'my recipes',
    featuredRecipes: 'featured',
    favouriteRecipes: 'favourites',
    searchResults: 'search results'
};
exports.articleSearchPageViewObjectCategory = {
    featuredRecipes: 'featured',
    searchResults: 'search results'
};
exports.foodType = {
    foodstuff: 'foodstuff',
    recipe: 'recipe'
};
exports.articleQuestionIntent = {
    singleQuestion: 'Single question',
    poll: 'Poll'
};
const parseExperimentsPayload = (experiments) => experiments.map(({ identifier, name, sdrnIdentifier, variant }) => ({
    custom: {
        experiment: identifier
    },
    name,
    variant,
    '@id': sdrnIdentifier
}));
exports.parseExperimentsPayload = parseExperimentsPayload;
exports.default = {
    type,
    objectType: exports.objectType,
    objectElementType: exports.objectElementType,
    objectCategory: exports.objectCategory,
    objectName,
    programType,
    originName: exports.originName,
    printRecipeOriginName,
    accessLevel
};
