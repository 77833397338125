"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disablePrivacySettings = exports.isPrerender = exports.isApp = exports.isAndroid = exports.isiOS = exports.parseUserAgent = void 0;
const parseUserAgent = (agent) => {
    const appVersionRegex = 'v[ei]ktklubbapp.*?/[0-9]+[.0-9]*[-0-9]*';
    const appVersion = agent.match(appVersionRegex);
    const platformiOS = agent.match('iPhone|iPad') ? 'iOS' : null;
    const platformAndroid = agent.match('Android') ? 'Android' : null;
    const platformDesktop = 'Desktop';
    const productiOSApp = agent.match('iPhone|iPad') && appVersion ? 'iOSApp' : null;
    const productAndroidApp = agent.match('Android') && appVersion ? 'AndroidApp' : null;
    const productWeb = 'Web';
    const isPrerender = agent.includes('prerender');
    const hasAppComponent = agent.includes('_app_');
    return {
        isApp: !!productiOSApp || !!productAndroidApp,
        productVersion: appVersion ? appVersion[0].split('/')[1] : null,
        platform: platformiOS || platformAndroid || platformDesktop,
        productType: productiOSApp || productAndroidApp || productWeb,
        isPrerender,
        disablePrivacySettings: (!!productiOSApp || !!productAndroidApp) && !hasAppComponent
    };
};
exports.parseUserAgent = parseUserAgent;
const isiOS = (agent) => (0, exports.parseUserAgent)(agent).platform === 'iOS';
exports.isiOS = isiOS;
const isAndroid = (agent) => (0, exports.parseUserAgent)(agent).platform === 'Android';
exports.isAndroid = isAndroid;
exports.isApp = (typeof window !== 'undefined'
    ? (0, exports.parseUserAgent)(window.navigator.userAgent)
    : {}).isApp;
exports.isPrerender = (typeof window !== 'undefined'
    ? (0, exports.parseUserAgent)(window.navigator.userAgent)
    : {}).isPrerender;
exports.disablePrivacySettings = (typeof window !== 'undefined'
    ? (0, exports.parseUserAgent)(window.navigator.userAgent)
    : {}).disablePrivacySettings;
